import Button from "components/ui/Button";
import { InputText } from "components/ui/form";
import { useForm } from "react-hook-form";
import firebase from "../../libs/firebase/FirebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import FirebaseAuthService from "../../libs/firebase/FirebaseAuthService";

type Inputs = {
  email: string;
  password: string;
};

export default function NewAccount() {
  const navigate = useNavigate();
  const { eventCode } = useParams();
  const [error, setError] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({ defaultValues: { email: "", password: "" } });

  const loginPath = useMemo(() => {
    return `/public/${eventCode}/login?message=Conta criada com sucesso`;
  }, [eventCode]);

  const onSubmit = async (data: Inputs) => {
    const { email, password } = data;

    try {
      await createUserWithEmailAndPassword(firebase.auth, email, password);

      navigate(loginPath);
    } catch (error: any) {
      console.error("Error creating user", error);

      switch (error.code) {
        case "auth/email-already-in-use":
          setError("E-mail já cadastrado");
          break;
        case "auth/weak-password":
          setError("A senha deve conter ao menos 6 caracteres");
          break;
        default:
          setError("Erro ao criar conta");
          break;
      }
    }
  };

  const handleCreationWithGoogle = async () => {
    try {
      await FirebaseAuthService.loginWithGoogle();
      navigate(loginPath, { replace: true });
    } catch (error) {
      console.log("Login error: ", error);
    }
  };

  return (
    <div className="flex items-center justify-center">
      <div className="min-w-[300px] flex flex-col">
        <h2 className="text-3xl">Crie sua conta</h2>

        <form
          className="w-full flex gap-3 mt-10"
          onSubmit={handleSubmit(onSubmit)}
          noValidate={true}
        >
          <InputText
            type="email"
            label="E-mail"
            register={register("email", {
              required: "Campo obrigatório",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "E-mail inválido",
              },
            })}
            field="email"
            isEditable={true}
            error={errors.email}
          />

          <InputText
            type="password"
            label="Senha"
            register={register("password", {
              required: "Campo obrigatório",
              minLength: {
                value: 6,
                message: "A senha deve conter ao menos 6 caracteres",
              },
            })}
            field="password"
            isEditable={true}
            error={errors.password}
          />

          <div className="w-full flex items-center justify-center mt-5">
            <Button type="submit" className="mt-5">
              Criar conta
            </Button>
          </div>
        </form>

        <div className="w-full flex items-center justify-center py-5">
          <span>-- OU --</span>
        </div>

        <div className="w-full flex items-center justify-center">
          <Button
            type="button"
            onClick={handleCreationWithGoogle}
            className="mt-2 flex items-center"
          >
            <span className="w-full">Faça login com o Google</span>
          </Button>
        </div>

        {error && <p className="text-red-400">{error}</p>}

        <div className="w-full flex items-center justify-center mt-5">
          <Link
            to={`/public/${eventCode}/login`}
            className="text-blue-400 mt-2"
          >
            Já possui uma conta?
          </Link>
        </div>
      </div>
    </div>
  );
}
